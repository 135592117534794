//@flow
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import './src/styles/globals.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './src/styles/global.scss';

import React from 'react';

import { config } from '@fortawesome/fontawesome-svg-core';
import { RootWrapper } from './src/RootWrapper';
import { init } from './src/shared/state/xstate/app/app.machine';

config.autoAddCss = false;

export const wrapRootElement = ({ element }) => {
  return <RootWrapper>{element}</RootWrapper>;
};

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
  init();
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 100);

  return false;
};

// export function onRouteUpdate(_) {
//   if (process.env.NODE_ENV === `production`) {
//     // wrap inside a timeout to ensure the title has properly been changed
//     setTimeout(() => {
//       const data = (window as any).dataLayer;
//       const eventName = `gatsby-route-change`;
//       console.log(window.dataLayer);
//       data.push({
//         event: eventName
//       });
//     }, 50);
//   }
// }
